import { useCallback, useEffect, useState } from 'react';
import ApiClient from '@/helpers/apiClient/ApiClient';

const useDataFetcher = (url, initialPeriod, sort, perPage, currentPage) => {
  const [periodState, setPeriodState] = useState(initialPeriod);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);

    ApiClient.call('get', url, { period: periodState, sort, per_page: perPage, page: currentPage })
      .data((data) => {
        setData(data);
      })
      .finally(() => setIsLoading(false));
  }, [url, periodState, sort, perPage, currentPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { periodState, setPeriodState, data, isLoading };
};

export default useDataFetcher;
