import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { PageHeader } from '@/components/page-headers/page-headers';
import useFormValues from '@/hooks/useFormValues';
import withAuthLayout from '@/layouts/withAuthLayout';
import { Main } from '@/container/styled';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormSubmit from '@/form/FormSubmit';
import { Cards } from '@/components/cards/frame/cards-frame';
import FormInput from '@/form/FormInput';
import FormCopyableInput from '@/form/FormCopyableInput';

const ShortLinksForm = () => {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('short-links');

  return (
    <>
      <PageHeader ghost title={id ? t('short_links.edit_page_header') : t('short_links.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={12}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <FormInput name="name" label={t('short_links.fields.name_short_links')} required error={errors.name} />
                  <FormInput name="destination" label={t('short_links.fields.link')} required error={errors.name} />
                  <Form.Item label={t('short_links.fields.generate_link')} name="url" validateStatus={null}>
                    <FormCopyableInput disabled placeholder={t('short_links.fields.generate_link_placeholder')} />
                  </Form.Item>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(ShortLinksForm);
