import React, { useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Cards } from '@/components/cards/frame/cards-frame';
import FilterButtons from '@/components/statistic/siteStatistic/FilteredButton';
import Spinner from '@/widgets/Spinner';
import useDataFetcher from '@/components/statistic/siteStatistic/hooks/useDataFetcher';

const TopProduct = () => {
  const [sort, setSort] = useState('total_quantity_sold');
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  const { periodState, setPeriodState, data: sellingData, isLoading } = useDataFetcher('site-statistic/top-products', 'year', sort, perPage, currentPage);

  const sellingColumns = [
    {
      title: t('site_statistic.top_products_title.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('site_statistic.top_products_title.price'),
      dataIndex: 'price',
      key: 'price',
      render: (value) => <span>{`₴ ${value}`}</span>,
      sorter: true,
      sortOrder: sort === 'price' ? 'descend' : null,
    },
    {
      title: t('site_statistic.top_products_title.total_quantity_sold'),
      dataIndex: 'total_quantity_sold',
      key: 'total_quantity_sold',
      sorter: true,
      sortOrder: sort === 'total_quantity_sold' ? 'descend' : null,
    },
    {
      title: t('site_statistic.top_products_title.revenue'),
      dataIndex: 'revenue',
      key: 'revenue',
      render: (value) => <span>₴ {Intl.NumberFormat('uk-UA').format(value)}</span>,
      sorter: true,
      sortOrder: sort === 'revenue' ? 'descend' : null,
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setSort(sorter.field || 'total_quantity_sold');
    setCurrentPage(pagination.current || 1);
    setPerPage(pagination.pageSize || 10);
  };

  return (
    <Cards
      isbutton={<FilterButtons periodState={periodState} setPeriodState={setPeriodState} />}
      title={t('site_statistic.top_products_title.main_title')}
      size="large"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="location-table">
          <Table
            columns={sellingColumns}
            dataSource={sellingData.map((item, key) => {
              return { ...item, key };
            })}
            onChange={handleTableChange}
            loading={isLoading}
            sortDirections={['descend']}
            pagination={{
              current: currentPage,
              pageSize: perPage,
              showSizeChanger: true,
              showTotal: (total) => t('table.total', { count: total }),
              onChange: (page) => setCurrentPage(page),
              onShowSizeChange: (_, size) => setPerPage(size),
            }}
          />
        </div>
      )}
    </Cards>
  );
};

export default TopProduct;
