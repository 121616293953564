import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { ErrorWrapper } from './style';
import { Main } from '../styled';
import { Button } from '@/components/buttons/buttons';

function Unauthorized() {
  const { t } = useTranslation();

  return (
    <Main>
      <ErrorWrapper>
        <Result
          status="403"
          title="403"
          subTitle={t('error_page.unauthorized_text')}
          style={{ padding: 0 }}
          extra={
            <NavLink to="/">
              <Button size="default" type="primary" to="/">
                {t('error_page.return_home')}
              </Button>
            </NavLink>
          }
        />
      </ErrorWrapper>
    </Main>
  );
}

export default Unauthorized;
