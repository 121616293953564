import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Empty } from 'antd';
import { useRef } from 'react';
import { EmptyWrapper } from '@/components/statistic/siteStatistic/styled';

const doughnutOptions = {
  cutoutPercentage: 60,
  maintainAspectRatio: false,
  responsive: false,
  legend: {
    display: false,
    position: 'bottom',
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
};

const ChartjsDonutChart = ({ datasets, labels }) => {
  const ref = useRef(null);

  return (
    <div>
      {labels.length ? (
        <>
          <Doughnut ref={ref} data={{ datasets, labels }} height={220} width={200} options={doughnutOptions} />

          <div className="align-center-v justify-content-between rd-labels">
            <div className="revenue-chat-label">
              {labels.map((label, key) => {
                return (
                  <div key={key} className="chart-label">
                    <span className="label-dot" style={{ backgroundColor: datasets[0].backgroundColor[key] }} />
                    {label}
                  </div>
                );
              })}
            </div>
            <div className="revenue-chart-data">
              {datasets.map((item, key) => {
                const { data } = item;
                return (
                  <div key={key}>
                    {data.map((value) => {
                      return (
                        <p key={value}>
                          <strong>₴ {Intl.NumberFormat('uk-UA').format(value)}</strong>
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <EmptyWrapper>
          <Empty />
        </EmptyWrapper>
      )}
    </div>
  );
};

ChartjsDonutChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
};

export default ChartjsDonutChart;
