import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Col, Radio, Row } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main, TopToolBox } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';
import useUrlParams from '@/hooks/useUrlParams';
import { useAuth } from '@/helpers/userContext';
import { AutoComplete } from '@/components/autoComplete/autoComplete';

const ShortLinksIndex = () => {
  const { t } = useTranslation();
  const { filters, setFilterOnly } = useUrlParams();
  const { user } = useAuth();

  const columns = React.useMemo(() => [
    {
      title: t('short_links.fields.name_short_links'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('short_links.fields.link'),
      dataIndex: 'url',
      key: 'url',
      render: (link) => {
        const isValidProtocol = /^(http|https):\/\//.test(link);
        return (
          <a target="_blank" href={`${isValidProtocol ? link : `//${link}`}`} rel="noreferrer">
            {link}
          </a>
        );
      },
    },
  ]);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('short_links.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/crm/short-links/create">+ {t('short_links.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>
      <TopToolBox>
        <Row gutter={24}>
          <Col xxl={16} xs={24}>
            <div className="product-list-action d-flex justify-content-between ">
              <div style={{ padding: '0 30px' }}>
                <AutoComplete
                  placeholder={t('short_links.search_placeholder')}
                  searchUrl="short-links/search"
                  onSelect={(value) => setFilterOnly('id', value)}
                  width="100%"
                  patterns
                />
              </div>
              <div className="product-list-action__tab" style={{ padding: '0 10px' }}>
                <Radio.Group onChange={(e) => setFilterOnly('user_id', e.target.value ? [e.target.value] : null)} defaultValue={null} value={filters?.status}>
                  <Radio.Button value="">{t('short_links.all')}</Radio.Button>
                  <Radio.Button value={user.id}>{t(`short_links.my`)}</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </Col>
        </Row>
      </TopToolBox>

      <Main>
        <Table route="crm/short-links" resource="short-links" columns={columns} permissionNamespace="crm.short_links" />
      </Main>
    </>
  );
};

export default withAuthLayout(ShortLinksIndex);
