import React, { createContext, useCallback, useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ApiClient from './apiClient/ApiClient';

const UserContext = createContext({});

const useAuth = () => {
  return useContext(UserContext);
};

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    ApiClient.call('POST', 'auth/logout').then(() => {
      setUser(null);
      Cookies.remove('token');
      navigate('/login');
    });
  }, [navigate]);

  ApiClient.setOnLogout(logout);

  const authorize = useCallback((token) => {
    ApiClient.authorize(token);

    return ApiClient.call('get', 'auth/me').data(({ data }) => {
      setUser(data);
      Cookies.set('token', token);
    });
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      authorize(token).finally(() => {
        setInitialized(true);
      });
    } else {
      setInitialized(true);
    }
  }, [authorize]);

  const checkPerm = (slug) => {
    return user.permissions.includes(slug) || user.is_admin;
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserContext.Provider value={{ user, setUser, authorize, logout, checkPerm }}>{initialized && children}</UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
};

export { UserContext, UserContextProvider, useAuth };
