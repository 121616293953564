import React, { useRef } from 'react';
import { Button, Input, message, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const FormCopyableInput = ({ label, placeholder, disabled, value, ...props }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const copyToClipboard = async () => {
    if (inputRef.current) {
      await navigator.clipboard.writeText(value);
      message.success(t('short_links.fields.copied'));
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Input ref={inputRef} value={value} disabled={disabled} placeholder={placeholder} {...props} />
      <Tooltip title={t('short_links.fields.copy_link')}>
        <Button icon={<CopyOutlined />} onClick={copyToClipboard} size="large" style={{ height: '48px' }} />
      </Tooltip>
    </div>
  );
};

FormCopyableInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default FormCopyableInput;
