import React, { createContext, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

const ErrorContext = createContext({});

const useError = () => {
  return useContext(ErrorContext);
};

// eslint-disable-next-line react/prop-types
const ErrorContextProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setError(null);
  }, [location]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
};

export { ErrorContextProvider, ErrorContext, useError };
