import { Card, Col, Row } from 'antd';
import { useCallback, useState } from 'react';
import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { StatisticChartsTitleWrap } from '@/components/statistic/conversion/styled';
import DateRangeFilter from '@/components/statistic/conversion/DateRangeFilter';
import Spinner from '@/widgets/Spinner';
import PieChart from '@/components/statistic/conversion/PieChart';
import useStatisticChartsData from '@/components/statistic/conversion/hooks/useStatisticChartsData';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { createPieChartDataCities } from '@/components/utilities/statistic/utilities';
import useFetchStatistics from '@/components/statistic/conversion/hooks/useFetchStatistics';
import ComboChart from '@/components/statistic/conversion/ComboChart';
import { StatisticsRecord } from '@/components/statistic/conversion/StatisticsRecord';

function StatisticsCities() {
  const [loading, setLoading] = useState(false);
  const [receiptsData, setReceiptsData] = useState([]);
  const [revenueData, setRevenueData] = useState({ offline: [], online: [], total: [], summary: 0 });
  const { chartsData, PeriodFilter, StoreFilter } = useStatisticChartsData(receiptsData);

  const { t } = useTranslation();

  const fetchReceipts = useCallback((start, end) => {
    setLoading(true);
    ApiClient.call('get', '/statistic/visitors-count', {
      start_date: start,
      end_date: end,
    })
      .data(({ data }) => {
        setReceiptsData(StatisticsRecord.cityFormat(data));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchRevenue = useCallback(async (start, end) => {
    setLoading(true);
    const [{ data: storeCity }, { data: noCity }] = await Promise.all([
      ApiClient.call('get', '/statistic/revenue', { start_date: start, end_date: end }).getData(),
      ApiClient.call('get', '/statistic/revenue', { start_date: start, end_date: end, no_city: 1 }).getData(),
    ]);

    const mergedData = [
      ...storeCity,
      ...noCity.map((elem) => ({
        ...elem,
        city_name: elem.city_name === '???' ? 'Крихітка' : elem.city_name,
      })),
    ];

    setRevenueData({
      offline: createPieChartDataCities('offline', mergedData),
      online: createPieChartDataCities('online', mergedData),
      total: createPieChartDataCities('total', mergedData),
      summary: sumBy(mergedData, 'total'),
    });
    setLoading(false);
  }, []);

  const { fetchCallback } = useFetchStatistics(fetchReceipts, fetchRevenue);

  return (
    <Col xs={24} sm={24}>
      <Card>
        <StatisticChartsTitleWrap>
          <Row className="statistic-chart-title_container">
            <h2 style={{ margin: 0 }}>{t('statistics.statistics_on_cities')}</h2>
            <PeriodFilter />
          </Row>
        </StatisticChartsTitleWrap>
        <DateRangeFilter onConfirm={fetchCallback} />
        <Row gutter={[16, 48]}>
          <Col xs={24} sm={24} md={24}>
            {loading ? <Spinner /> : <ComboChart length={receiptsData.length} data={chartsData} />}
            <StoreFilter />
          </Col>
          <Col xs={24} sm={24} md={24}>
            {loading ? (
              <Spinner />
            ) : (
              <PieChart offlineData={revenueData.offline} onlineData={revenueData.online} totalData={revenueData.total} summary={revenueData.summary} />
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default StatisticsCities;
