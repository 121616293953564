import { sumBy } from 'lodash';
import { groupBy } from 'lodash/collection';

export const colorsArray = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#FF00FF',
  '#00FFFF',
  '#FFA500',
  '#800080',
  '#008000',
  '#000080',
  '#800000',
  '#808000',
  '#008080',
  '#808080',
  '#C0C0C0',
  '#FF4500',
  '#FFD700',
  '#FF6347',
  '#FF8C00',
  '#A52A2A',
];

function calcTotalData(data) {
  const offline = sumBy(data, 'offline');
  const online = sumBy(data, 'online');

  return {
    offline,
    online,
  };
}

export function darkenColor(hex, percent = 30) {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.floor((r * (100 - percent)) / 100);
  g = Math.floor((g * (100 - percent)) / 100);
  b = Math.floor((b * (100 - percent)) / 100);

  const darkenedHex = `#${`0${r.toString(16)}`.slice(-2)}${`0${g.toString(16)}`.slice(-2)}${`0${b.toString(16)}`.slice(-2)}`;

  return darkenedHex;
}

export function generateSerias(count) {
  return Array.from({ length: count }).flatMap((_, i) => {
    const randomColor = colorsArray[i];
    return [`color:${randomColor}`, `color:${darkenColor(randomColor)}`];
  });
}

export function parseKeyValueString(values) {
  const objArr = values.map((pair) => {
    const [key, val] = pair.split(':');

    return { [key.trim()]: val.trim() };
  });

  return { ...objArr };
}

export function createPieChartData(type, data) {
  const resultData = [['store', type]];

  if (type === 'total') {
    const { online, offline } = calcTotalData(data);

    resultData.push(['Онлайн', online], ['Офлайн', offline]);
    return resultData;
  }

  data.forEach((item) => {
    if (item[type] !== 0) {
      resultData.push([item.store_name, item[type]]);
    }
  });
  return resultData;
}

export function createPieChartDataCities(type, data) {
  const resultData = [['city', type]];

  if (type === 'total') {
    const { online, offline } = calcTotalData(data);

    resultData.push(['Онлайн', online], ['Офлайн', offline]);
    return resultData;
  }

  // eslint-disable-next-line camelcase
  const groupByCityName = groupBy(data, ({ city_name }) => city_name);

  // eslint-disable-next-line no-unused-vars
  const arrayGroupCityData = Object.entries(groupByCityName).map(([_, value]) => {
    return {
      city_name: value[0].city_name,
      online: sumBy(value, 'online'),
      offline: sumBy(value, 'offline'),
      data: sumBy(value, 'data'),
    };
  });

  arrayGroupCityData.forEach((item) => {
    if (item[type] !== 0) {
      resultData.push([item.city_name, item[type]]);
    }
  });
  return resultData;
}
